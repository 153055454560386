import React, {useContext} from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Header } from '../../components/common/header'
import { Footer } from '../../components/common/footer'
import { Layout} from '../../layouts'
import './index.sass'

export default function BlogTemplate({ data, pageContext }) {
  const { country } = pageContext

  const { mdx: post } = data

  return (
    <Layout pageTitle={post.frontmatter.title} country={country} language={pageContext.language}>
      <Helmet>
        <meta name="description" content={post.frontmatter.meta} />
      </Helmet>
      <Header linkColor="#272755" buttonColor="#7767E4" country={country} />
      <div className="blog-post">
        <div className="blog-type-container">
          <span className="blog-type-label">{post.frontmatter.type}</span>
        </div>
        <h1 className="header-title">{post.frontmatter.title}</h1>
        <span className="date-label">{post.frontmatter.date}</span>
        <GatsbyImage
          image={getImage(post.frontmatter.image.childImageSharp.gatsbyImageData)}
          className="header-image"
          alt=""
        />
        <MDXRenderer>
          {post.body}
        </MDXRenderer>
      </div>
      <Footer country={country} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostByPath($subpath: String!) {
    mdx(frontmatter: { path: { eq: $subpath } }) {
      body
      frontmatter {
        date(formatString: "dddd, MMMM DD, YYYY")
        path
        title
        type
        description
        meta
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
